import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path1/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "basic-concept"
    }}>{`Basic concept`}</h1>
    <p>{`If your are coming from `}<a parentName="p" {...{
        "href": "https://redux-observable.js.org"
      }}>{`redux-observable`}</a>{`, you may feel familiar with some of concepts in `}<inlineCode parentName="p">{`Sigi`}</inlineCode>{`.`}</p>
    <p><strong parentName="p">{`We also borrowed some contents from docs of redux-observable.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      